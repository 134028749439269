.App { /* Base font style */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 15.2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.8500);
  background-color: #f6f6f6;
}

.App .baseFont * { /* Base font style override for UI framework components */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 15.2px;
}

.App .appBg {
  background-color: #f6f6f6;
}

.App .cardBg {
  background-color: white;
}

.App .baseTextBg { /* Base text color as background */
  background-color: rgba(0, 0, 0, 0.8500);
}

.App .secondaryTextColor {
  color: rgba(0, 0, 0, 0.5000);
}

.App .secondaryTextBg {
  background-color: rgba(0, 0, 0, 0.5000);
}

.App .primaryTextColor {
  color: #65d0c4;
}

.App .primaryBg {
  background-color: #65d0c4;
}

.App .highlightTextColor {
  color: #65d0c4;
}

.App .highlightBg {
  background-color: #65d0c4;
}

/* Styles to customize the UI framework plugin (MUI, https://muicss.com) */
.mui-btn {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.mui-btn--primary {
    background-color: #65d0c4;
}
.mui-btn--primary:active, .mui-btn--primary:focus, .mui-btn--primary:hover {
    background-color: #70d3ca;
}
.mui-btn--accent {
    background-color: #65d0c4;
}
.mui-btn--accent:active, .mui-btn--accent:focus, .mui-btn--accent:hover {
    background-color: #70d3ca;
}
.mui-textfield {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-checkbox, .mui-radio {  /* Reset UI framework styling that affects layout */
    margin-top: 0;
    margin-bottom: 0;
}
.mui-select {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-btn+.mui-btn {  /* Reset UI framework styling that affects layout */
    margin-left: 0;
}
.mui-btn--fab {  /* Reset UI framework styling that breaks compositing */
    z-index: auto;
}

@keyframes fadeIn {
    0%   { opacity: 0.0; }
    100% { opacity: 1.0; }
}
@keyframes slideIn_right {
    0%   { transform: translateX(100%); }
    100% { transform: translateX(0); }
}
@keyframes slideIn_bottom {
    0%   { transform: translateY(100%); }
    100% { transform: translateY(0); }
}

/* System font classes */

.App .systemFontRegular {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}
.App .systemFontBold {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
}
.App .systemFontItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-style: italic;
}
.App .systemFontBoldItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
  font-style: italic;
}

/* App-wide text styles specified in React Studio */

.App .actionFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 14.2px;
  font-weight: 400;
}
.App .actionFont * {
  font-size: 14.2px;
}
.App .headlineFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 19.0px;
  font-weight: 400;
}
.App .headlineFont * {
  font-size: 19.0px;
}
.App .navBar {
  background-color: #65d0c4;
  color: white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  min-height: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 18px;
}

.App .navBar a {
  color: white;
}

.App .navBar .title {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 4px;
}

.App .navBar .backBtn {
  position: absolute;
  padding: 4px;
  margin-left: 14px;
  cursor: pointer;
}

.App .navBar > div {
  margin-top: 7px;
}

@media (min-width: 568px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 768px) {
  .App .navBar {
    height: 75px;
    min-height: 75px;
  }
  .App .navBar > div {
    margin-top: 19px;
  }
}
@media (min-width: 1024px) {
  .App .navBar {
    height: 75px;
    min-height: 75px;
  }
  .App .navBar > div {
    margin-top: 19px;
  }
}
@media (min-width: 320px) {
  .mui-container {
    max-width: 320px;
  }
}
@media (min-width: 568px) {
  .mui-container {
    max-width: 568px;
  }
}
@media (min-width: 768px) {
  .mui-container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .mui-container {
    max-width: 1024px;
  }
}

.AppScreen {
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.App .background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App .layoutFlow {
  position: relative;
  width: 100%;
  pointer-events: none;
}
.App .layoutFlow:before {  /* Prevent margin collapse */
  content: "";
  display: table;
  clear: both;
}
.App .layoutFlow .flowRow {
  position: relative;
  width: 100%;
}

.App .screenFgContainer {
  /* Items in the foreground group within a screen are placed with fixed positioning */
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.App .foreground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App ul {
  margin: 0;
  padding: 0;
}
.App li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}
.App .hasNestedComps {
  position: relative;
}
.App .hasNestedComps:after {
  content: "";
  display: table;
  clear: both;
}
.App .hasNestedComps .background {
  position: absolute;
}
.App .hasNestedComps .layoutFlow {
  position: relative;
}
.App .bottomAlignedContent {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.App .blockInputOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: auto;
  z-index: 99999999;
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .background > .state0_elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .ConnexionScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .ConnexionScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .ConnexionScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state0_elText986242 {
    overflow: hidden;
    position: relative;
    margin-top: 90px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elText986242 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state0_elText986242 {
    overflow: hidden;
    position: relative;
    margin-top: 90px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elText986242 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state0_elText986242 {
    overflow: hidden;
    position: relative;
    margin-top: 90px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elText986242 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state0_elText986242 {
    overflow: hidden;
    position: relative;
    margin-top: 90px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elText986242 > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state0_elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 68px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elText2 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state0_elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 68px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elText2 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state0_elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 68px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elText2 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state0_elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 68px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elText2 > * { 
  width: 236px;
  }
  
}

.circularProgressIndicator {
    position: relative;
    width: 50px;
    height: 50px;
    display: none;
}
.circularProgressIndicator-active {
    animation: rotate 2s linear infinite;
    display: inline;
}
.circularProgress-animatedPath {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
    animation: circularProgress-dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
}
@keyframes rotate{
    100%{
    transform: rotate(360deg);
    }
}
@keyframes circularProgress-dash{
    0%{
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50%{
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100%{
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}



/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state0_elProgress466744 {
    position: relative;
    margin-top: 45px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elProgress466744 > * { 
  width: 48px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state0_elProgress466744 {
    position: relative;
    margin-top: 45px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elProgress466744 > * { 
  width: 48px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state0_elProgress466744 {
    position: relative;
    margin-top: 44px;
    width: 47px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elProgress466744 > * { 
  width: 47px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state0_elProgress466744 {
    position: relative;
    margin-top: 44px;
    width: 47px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elProgress466744 > * { 
  width: 47px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state1_elText986242 {
    overflow: hidden;
    position: relative;
    margin-top: 90px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elText986242 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state1_elText986242 {
    overflow: hidden;
    position: relative;
    margin-top: 90px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elText986242 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state1_elText986242 {
    overflow: hidden;
    position: relative;
    margin-top: 90px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elText986242 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state1_elText986242 {
    overflow: hidden;
    position: relative;
    margin-top: 90px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elText986242 > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state1_elText3 {
    position: relative;
    margin-top: 30px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elText3 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state1_elText3 {
    position: relative;
    margin-top: 30px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elText3 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state1_elText3 {
    position: relative;
    margin-top: 30px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elText3 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state1_elText3 {
    position: relative;
    margin-top: 30px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elText3 > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state1_elTextCopy {
    position: relative;
    margin-top: 30px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elTextCopy > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state1_elTextCopy {
    position: relative;
    margin-top: 30px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elTextCopy > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state1_elTextCopy {
    position: relative;
    margin-top: 30px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elTextCopy > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state1_elTextCopy {
    position: relative;
    margin-top: 30px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elTextCopy > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state1_elTextCopy2 {
    position: relative;
    margin-top: 30px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elTextCopy2 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state1_elTextCopy2 {
    position: relative;
    margin-top: 30px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elTextCopy2 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state1_elTextCopy2 {
    position: relative;
    margin-top: 30px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elTextCopy2 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state1_elTextCopy2 {
    position: relative;
    margin-top: 30px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elTextCopy2 > * { 
  width: 236px;
  }
  
}

.ConnexionScreen .state1_elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.ConnexionScreen .state1_elButton button:focus {
  box-shadow: 0 0 5px rgba(96, 206, 196, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state1_elButton {
    line-height: 35px;
    position: relative;
    margin-top: 47px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elButton > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state1_elButton {
    line-height: 35px;
    position: relative;
    margin-top: 47px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elButton > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state1_elButton {
    line-height: 35px;
    position: relative;
    margin-top: 46px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state1_elButton {
    line-height: 35px;
    position: relative;
    margin-top: 46px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state1_elProgress466744 {
    position: relative;
    margin-top: 45px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elProgress466744 > * { 
  width: 48px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state1_elProgress466744 {
    position: relative;
    margin-top: 45px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elProgress466744 > * { 
  width: 48px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state1_elProgress466744 {
    position: relative;
    margin-top: 44px;
    width: 47px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elProgress466744 > * { 
  width: 47px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state1_elProgress466744 {
    position: relative;
    margin-top: 44px;
    width: 47px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elProgress466744 > * { 
  width: 47px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state2_elText986242 {
    overflow: hidden;
    position: relative;
    margin-top: 90px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elText986242 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state2_elText986242 {
    overflow: hidden;
    position: relative;
    margin-top: 90px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elText986242 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state2_elText986242 {
    overflow: hidden;
    position: relative;
    margin-top: 90px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elText986242 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state2_elText986242 {
    overflow: hidden;
    position: relative;
    margin-top: 90px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elText986242 > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state2_elEmail {
    position: relative;
    margin-top: 23px;
    width: 226px;
    margin-left: calc(50.0% - 113px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elEmail > * { 
  width: 226px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state2_elEmail {
    position: relative;
    margin-top: 23px;
    width: 226px;
    margin-left: calc(50.0% - 113px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elEmail > * { 
  width: 226px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state2_elEmail {
    position: relative;
    margin-top: 23px;
    width: 225px;
    margin-left: calc(50.0% - 112px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elEmail > * { 
  width: 225px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state2_elEmail {
    position: relative;
    margin-top: 23px;
    width: 225px;
    margin-left: calc(50.0% - 112px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elEmail > * { 
  width: 225px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state2_elPassword {
    position: relative;
    margin-top: 23px;
    width: 226px;
    margin-left: calc(50.0% - 113px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elPassword > * { 
  width: 226px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state2_elPassword {
    position: relative;
    margin-top: 23px;
    width: 226px;
    margin-left: calc(50.0% - 113px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elPassword > * { 
  width: 226px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state2_elPassword {
    position: relative;
    margin-top: 23px;
    width: 225px;
    margin-left: calc(50.0% - 112px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elPassword > * { 
  width: 225px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state2_elPassword {
    position: relative;
    margin-top: 23px;
    width: 225px;
    margin-left: calc(50.0% - 112px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elPassword > * { 
  width: 225px;
  }
  
}

.ConnexionScreen .state2_elConnexion button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.ConnexionScreen .state2_elConnexion button:focus {
  box-shadow: 0 0 5px rgba(96, 206, 196, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state2_elConnexion {
    line-height: 35px;
    position: relative;
    margin-top: 45px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elConnexion > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state2_elConnexion {
    line-height: 35px;
    position: relative;
    margin-top: 45px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elConnexion > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state2_elConnexion {
    line-height: 35px;
    position: relative;
    margin-top: 44px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elConnexion > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state2_elConnexion {
    line-height: 35px;
    position: relative;
    margin-top: 44px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elConnexion > * { 
  width: 144px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state2_elProgress466744 {
    position: relative;
    margin-top: 45px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elProgress466744 > * { 
  width: 48px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state2_elProgress466744 {
    position: relative;
    margin-top: 45px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elProgress466744 > * { 
  width: 48px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state2_elProgress466744 {
    position: relative;
    margin-top: 44px;
    width: 47px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elProgress466744 > * { 
  width: 47px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state2_elProgress466744 {
    position: relative;
    margin-top: 44px;
    width: 47px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elProgress466744 > * { 
  width: 47px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DecheteriesScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .DecheteriesScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .DecheteriesScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .DecheteriesScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .DecheteriesScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 100px;
    width: 150px;
    margin-left: 3.0%;
  }
  .DecheteriesScreen > .layoutFlow > .elText2 > * { 
  width: 150px;
  }
  
@media (min-width: 568px) {
  .DecheteriesScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 100px;
    width: 150px;
    margin-left: 3.0%;
  }
  .DecheteriesScreen > .layoutFlow > .elText2 > * { 
  width: 150px;
  }
  
}
@media (min-width: 768px) {
  .DecheteriesScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 100px;
    width: 149px;
    margin-left: 3.0%;
  }
  .DecheteriesScreen > .layoutFlow > .elText2 > * { 
  width: 149px;
  }
  
}
@media (min-width: 1024px) {
  .DecheteriesScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 100px;
    width: 149px;
    margin-left: 3.0%;
  }
  .DecheteriesScreen > .layoutFlow > .elText2 > * { 
  width: 149px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DecheteriesScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: -24px;
    width: 181px;
    margin-left: calc(3.0% + 186px);
  }
  .DecheteriesScreen > .layoutFlow > .elPicker > * { 
  width: 181px;
  }
  
@media (min-width: 568px) {
  .DecheteriesScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: -24px;
    width: 181px;
    margin-left: calc(3.0% + 186px);
  }
  .DecheteriesScreen > .layoutFlow > .elPicker > * { 
  width: 181px;
  }
  
}
@media (min-width: 768px) {
  .DecheteriesScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: -24px;
    width: 180px;
    margin-left: calc(3.0% + 185px);
  }
  .DecheteriesScreen > .layoutFlow > .elPicker > * { 
  width: 180px;
  }
  
}
@media (min-width: 1024px) {
  .DecheteriesScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: -24px;
    width: 180px;
    margin-left: calc(3.0% + 185px);
  }
  .DecheteriesScreen > .layoutFlow > .elPicker > * { 
  width: 180px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DecheteriesScreen > .layoutFlow > .elTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 120px;
    margin-left: 3.0%;
  }
  .DecheteriesScreen > .layoutFlow > .elTextCopy > * { 
  width: 120px;
  }
  
@media (min-width: 568px) {
  .DecheteriesScreen > .layoutFlow > .elTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 120px;
    margin-left: 3.0%;
  }
  .DecheteriesScreen > .layoutFlow > .elTextCopy > * { 
  width: 120px;
  }
  
}
@media (min-width: 768px) {
  .DecheteriesScreen > .layoutFlow > .elTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 119px;
    margin-left: 3.0%;
  }
  .DecheteriesScreen > .layoutFlow > .elTextCopy > * { 
  width: 119px;
  }
  
}
@media (min-width: 1024px) {
  .DecheteriesScreen > .layoutFlow > .elTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 119px;
    margin-left: 3.0%;
  }
  .DecheteriesScreen > .layoutFlow > .elTextCopy > * { 
  width: 119px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DecheteriesScreen > .layoutFlow > .elTextCopy4 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    width: 120px;
    margin-left: calc(3.0% + 186px);
  }
  .DecheteriesScreen > .layoutFlow > .elTextCopy4 > * { 
  width: 120px;
  }
  
@media (min-width: 568px) {
  .DecheteriesScreen > .layoutFlow > .elTextCopy4 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    width: 120px;
    margin-left: calc(3.0% + 186px);
  }
  .DecheteriesScreen > .layoutFlow > .elTextCopy4 > * { 
  width: 120px;
  }
  
}
@media (min-width: 768px) {
  .DecheteriesScreen > .layoutFlow > .elTextCopy4 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    width: 119px;
    margin-left: calc(3.0% + 185px);
  }
  .DecheteriesScreen > .layoutFlow > .elTextCopy4 > * { 
  width: 119px;
  }
  
}
@media (min-width: 1024px) {
  .DecheteriesScreen > .layoutFlow > .elTextCopy4 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    width: 119px;
    margin-left: calc(3.0% + 185px);
  }
  .DecheteriesScreen > .layoutFlow > .elTextCopy4 > * { 
  width: 119px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DecheteriesScreen > .layoutFlow > .elTextCopy3 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 120px;
    margin-left: 3.0%;
  }
  .DecheteriesScreen > .layoutFlow > .elTextCopy3 > * { 
  width: 120px;
  }
  
@media (min-width: 568px) {
  .DecheteriesScreen > .layoutFlow > .elTextCopy3 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 120px;
    margin-left: 3.0%;
  }
  .DecheteriesScreen > .layoutFlow > .elTextCopy3 > * { 
  width: 120px;
  }
  
}
@media (min-width: 768px) {
  .DecheteriesScreen > .layoutFlow > .elTextCopy3 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 119px;
    margin-left: 3.0%;
  }
  .DecheteriesScreen > .layoutFlow > .elTextCopy3 > * { 
  width: 119px;
  }
  
}
@media (min-width: 1024px) {
  .DecheteriesScreen > .layoutFlow > .elTextCopy3 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 119px;
    margin-left: 3.0%;
  }
  .DecheteriesScreen > .layoutFlow > .elTextCopy3 > * { 
  width: 119px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DecheteriesScreen > .layoutFlow > .elTextCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    width: 120px;
    margin-left: calc(3.0% + 186px);
  }
  .DecheteriesScreen > .layoutFlow > .elTextCopy2 > * { 
  width: 120px;
  }
  
@media (min-width: 568px) {
  .DecheteriesScreen > .layoutFlow > .elTextCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    width: 120px;
    margin-left: calc(3.0% + 186px);
  }
  .DecheteriesScreen > .layoutFlow > .elTextCopy2 > * { 
  width: 120px;
  }
  
}
@media (min-width: 768px) {
  .DecheteriesScreen > .layoutFlow > .elTextCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    width: 119px;
    margin-left: calc(3.0% + 185px);
  }
  .DecheteriesScreen > .layoutFlow > .elTextCopy2 > * { 
  width: 119px;
  }
  
}
@media (min-width: 1024px) {
  .DecheteriesScreen > .layoutFlow > .elTextCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    width: 119px;
    margin-left: calc(3.0% + 185px);
  }
  .DecheteriesScreen > .layoutFlow > .elTextCopy2 > * { 
  width: 119px;
  }
  
}

  .DecheteriesScreen .elList:after {
    content: "";
    display: table;
    clear: both;
  }

  .DecheteriesScreen .elList .gridItem {
    float: left;
    position: relative;
  }

@media (min-width: 1024px) {
  .DecheteriesScreen .elList .gridItem {
    width: calc(100% / 9 - 9.0px*(8/9));
    overflow: hidden;
  }

  .DecheteriesScreen .elList .cols9_0 {
    margin-right: 9.0px;
    margin-bottom: 9.0px;
    clear: left;
  }
  .DecheteriesScreen .elList .cols9_1 {
    margin-right: 9.0px;
    margin-bottom: 9.0px;
  }
  .DecheteriesScreen .elList .cols9_2 {
    margin-right: 9.0px;
    margin-bottom: 9.0px;
  }
  .DecheteriesScreen .elList .cols9_3 {
    margin-right: 9.0px;
    margin-bottom: 9.0px;
  }
  .DecheteriesScreen .elList .cols9_4 {
    margin-right: 9.0px;
    margin-bottom: 9.0px;
  }
  .DecheteriesScreen .elList .cols9_5 {
    margin-right: 9.0px;
    margin-bottom: 9.0px;
  }
  .DecheteriesScreen .elList .cols9_6 {
    margin-right: 9.0px;
    margin-bottom: 9.0px;
  }
  .DecheteriesScreen .elList .cols9_7 {
    margin-right: 9.0px;
    margin-bottom: 9.0px;
  }
  .DecheteriesScreen .elList .cols9_8 {
    margin-bottom: 9.0px;
  }
  .DecheteriesScreen .elList .cols9_8:after {
    content: "";
    display: table;
    clear: both;
  }

}

@media (min-width: 768px) and (max-width: 1023px) {
  .DecheteriesScreen .elList .gridItem {
    width: calc(100% / 5 - 9.0px*(4/5));
    overflow: hidden;
  }

  .DecheteriesScreen .elList .cols5_0 {
    margin-right: 9.0px;
    margin-bottom: 9.0px;
    clear: left;
  }
  .DecheteriesScreen .elList .cols5_1 {
    margin-right: 9.0px;
    margin-bottom: 9.0px;
  }
  .DecheteriesScreen .elList .cols5_2 {
    margin-right: 9.0px;
    margin-bottom: 9.0px;
  }
  .DecheteriesScreen .elList .cols5_3 {
    margin-right: 9.0px;
    margin-bottom: 9.0px;
  }
  .DecheteriesScreen .elList .cols5_4 {
    margin-bottom: 9.0px;
  }
  .DecheteriesScreen .elList .cols9_8:after { }
  .DecheteriesScreen .elList .cols5_4:after {
    content: "";
    display: table;
    clear: both;
  }

}

@media (min-width: 568px) and (max-width: 767px) {
  .DecheteriesScreen .elList .gridItem {
    width: calc(100% / 2 - 9.1px*(1/2));
    overflow: hidden;
  }

  .DecheteriesScreen .elList .cols5_0 { margin-right: 0; margin-bottom: 0; }
  .DecheteriesScreen .elList .cols5_1 { margin-right: 0; margin-bottom: 0; }
  .DecheteriesScreen .elList .cols5_2 { margin-right: 0; margin-bottom: 0; }
  .DecheteriesScreen .elList .cols5_3 { margin-right: 0; margin-bottom: 0; }
  .DecheteriesScreen .elList .cols5_4 { margin-right: 0; margin-bottom: 0; }
  .DecheteriesScreen .elList .cols2_0 {
    margin-right: 9.1px;
    margin-bottom: 9.1px;
    clear: left;
  }
  .DecheteriesScreen .elList .cols2_1 {
    margin-bottom: 9.1px;
  }
  .DecheteriesScreen .elList .cols9_8:after { }
  .DecheteriesScreen .elList .cols5_4:after { }
  .DecheteriesScreen .elList .cols2_1:after {
    content: "";
    display: table;
    clear: both;
  }

}

@media (max-width: 567px) {
  .DecheteriesScreen .elList .gridItem {
    width: calc(100% / 2 - 9.1px*(1/2));
    overflow: hidden;
  }

  .DecheteriesScreen .elList .cols5_0 { margin-right: 0; margin-bottom: 0; }
  .DecheteriesScreen .elList .cols5_1 { margin-right: 0; margin-bottom: 0; }
  .DecheteriesScreen .elList .cols5_2 { margin-right: 0; margin-bottom: 0; }
  .DecheteriesScreen .elList .cols5_3 { margin-right: 0; margin-bottom: 0; }
  .DecheteriesScreen .elList .cols5_4 { margin-right: 0; margin-bottom: 0; }
  .DecheteriesScreen .elList .cols2_0 { margin-right: 0; margin-bottom: 0; }
  .DecheteriesScreen .elList .cols2_1 { margin-right: 0; margin-bottom: 0; }
  .DecheteriesScreen .elList .cols2_0 {
    margin-right: 9.1px;
    margin-bottom: 9.1px;
    clear: left;
  }
  .DecheteriesScreen .elList .cols2_1 {
    margin-bottom: 9.1px;
  }
  .DecheteriesScreen .elList .cols9_8:after { }
  .DecheteriesScreen .elList .cols5_4:after { }
  .DecheteriesScreen .elList .cols2_1:after { }
  .DecheteriesScreen .elList .cols2_1:after {
    content: "";
    display: table;
    clear: both;
  }

}



/* Narrowest size for this element's responsive layout */
  .DecheteriesScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 40px;
    margin-left: 3.0%;
    margin-right: calc(3.0% + 0px);
    width: calc(94.0% - 0px);
  }
  .DecheteriesScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .DecheteriesScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 40px;
    margin-left: 3.0%;
    margin-right: calc(3.0% + 0px);
    width: calc(94.0% - 0px);
  }
  .DecheteriesScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .DecheteriesScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 40px;
    margin-left: 3.0%;
    margin-right: calc(3.0% + 0px);
    width: calc(94.0% - 0px);
  }
  .DecheteriesScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .DecheteriesScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 40px;
    margin-left: 3.0%;
    margin-right: calc(3.0% + 0px);
    width: calc(94.0% - 0px);
  }
  .DecheteriesScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DecheteriesScreen > .layoutFlow > .elEmbed {
    position: relative;
    margin-top: 0px;
    width: 400px;
    margin-left: 3.0%;
  }
  .DecheteriesScreen > .layoutFlow > .elEmbed > * { 
  width: 400px;
  }
  
@media (min-width: 568px) {
  .DecheteriesScreen > .layoutFlow > .elEmbed {
    position: relative;
    margin-top: 0px;
    width: 400px;
    margin-left: 3.0%;
  }
  .DecheteriesScreen > .layoutFlow > .elEmbed > * { 
  width: 400px;
  }
  
}
@media (min-width: 768px) {
  .DecheteriesScreen > .layoutFlow > .elEmbed {
    position: relative;
    margin-top: 0px;
    width: 398px;
    margin-left: 3.0%;
  }
  .DecheteriesScreen > .layoutFlow > .elEmbed > * { 
  width: 398px;
  }
  
}
@media (min-width: 1024px) {
  .DecheteriesScreen > .layoutFlow > .elEmbed {
    position: relative;
    margin-top: 0px;
    width: 398px;
    margin-left: 3.0%;
  }
  .DecheteriesScreen > .layoutFlow > .elEmbed > * { 
  width: 398px;
  }
  
}

.DecheteriesScreen .elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.DecheteriesScreen .elIconButton:focus {
  box-shadow: 0 0 5px rgba(254, 255, 255, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .DecheteriesScreen > .screenFgContainer > .foreground > .elIconButton {
    position: fixed;
    right: 10px;
    top: 13px;
    width: 20px;
    height: 20px;
  }
@media (min-width: 568px) {
  .DecheteriesScreen > .screenFgContainer > .foreground > .elIconButton {
    right: 10px;
    top: 13px;
    width: 20px;
    height: 20px;
  }
}
@media (min-width: 768px) {
  .DecheteriesScreen > .screenFgContainer > .foreground > .elIconButton {
    right: 10px;
    top: 13px;
    width: 20px;
    height: 20px;
  }
}
@media (min-width: 1024px) {
  .DecheteriesScreen > .screenFgContainer > .foreground > .elIconButton {
    right: 10px;
    top: 13px;
    width: 20px;
    height: 20px;
  }
}

/* This component has a fixed-size layout */
  .Niveau {
    min-height: 159px;
    width: 100%; /* This component was designed using a width of 159px */
    height: 100%;
  }
  .Niveau > .background > .containerMinHeight {
    min-height: 159px;
  }


  .Niveau > .layoutFlow > .flowRow > .elN15_4 {
    position: absolute;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN15_4 > * { 
    width: 100%;
  height: 0px;
  }
  
  .flowRow_Niveau_elN15_4_668229 { 
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN15_3 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN15_3 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN15_2 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN15_2 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN15_1 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN15_1 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN15_0 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN15_0 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN14_4 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN14_4 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN14_3 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN14_3 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN14_2 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN14_2 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN14_1 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN14_1 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN14_0 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN14_0 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN13_4 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN13_4 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN13_3 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN13_3 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN13_2 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN13_2 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN13_1 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN13_1 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN13_0 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN13_0 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN12_4 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN12_4 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN12_3 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN12_3 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN12_2 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN12_2 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN12_1 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN12_1 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN12_0 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN12_0 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN11_4 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN11_4 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN11_3 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN11_3 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN11_2 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN11_2 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN11_1 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN11_1 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN11_0 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN11_0 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN10_4 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN10_4 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN10_3 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN10_3 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN10_2 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN10_2 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN10_1 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN10_1 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN10_0 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN10_0 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN9_4 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN9_4 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN9_3 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN9_3 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN9_2 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN9_2 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN9_1 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN9_1 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN9_0 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN9_0 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN8_4 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN8_4 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN8_3 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN8_3 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN8_2 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN8_2 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN8_1 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN8_1 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN8_0 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN8_0 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN7_4 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN7_4 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN7_3 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN7_3 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN7_2 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN7_2 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN7_1 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN7_1 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN7_0 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN7_0 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN6_4 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN6_4 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN6_3 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN6_3 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN6_2 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN6_2 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN6_1 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN6_1 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN6_0 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN6_0 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN5_4 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN5_4 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN5_3 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN5_3 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN5_2 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN5_2 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN5_1 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN5_1 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN5_0 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN5_0 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN4_4 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN4_4 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN4_3 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN4_3 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN4_2 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN4_2 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN4_1 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN4_1 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN4_0 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN4_0 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN3_4 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN3_4 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN3_3 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN3_3 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN3_2 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN3_2 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN3_1 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN3_1 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN3_0 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN3_0 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN2_4 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN2_4 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN2_3 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN2_3 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN2_2 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN2_2 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN2_1 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN2_1 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN2_0 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN2_0 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN1_4 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN1_4 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN1_3 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN1_3 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN1_2 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN1_2 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN1_1 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN1_1 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN1_0 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN1_0 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN0_4 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN0_4 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN0_3 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN0_3 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN0_2 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN0_2 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN0_1 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN0_1 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elN0_0 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Niveau > .layoutFlow > .flowRow > .elN0_0 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Niveau > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    top: 0px;
    width: 100%;
  }
  .Niveau > .layoutFlow > .flowRow > .elImage > * { 
  width: 158px;
  height: 92px;
  }
  


  .Niveau > .foreground > .elText {
    position: absolute;
    top: 104px;
    width: 158px;
    height: 41px;
  }

